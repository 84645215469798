export const locale = {

    lang: 'TRTR',
  
    data: {

     loginButton: "Giriş",

    userName: "Kullanıcı Adı",

    pass: "Parola",

    remember: "Beni Hatırla",

    forgotPass: "Parolayı Unuttum",

    modules: "Modüller",

    generic: "Genel",

    search: "Ara",

    logout: "Çıkış",

    waitingJob: "Bekleyen İşlerim",

    myStarted: "Başlattığım",

    myIncluded: "Dahil Olduğum",

    myCompleted: "Tamamladığım İşler",

    jobList: "İş Listesi",
    formNo: "Form No",
    status: "Durum",
    starter: "Başlatan",
    assigned: "Atanan",
    timeoutDate: "Zaman Aşımı Tarihi",
    startDate: "Başlangıç Tarihi",
    processing: "İşleniyor",
    you: "sen",
    history: "Geçmiş",
    description: "Açıklama",
    date: "Tarih",
    growShrink: "Büyüt/Küçült",
    close: "Kapat",
    gridReset: "Grid Ayarlarını Sıfırla",
    columnSelect: "Sütun Seçimi",
    jobName: "İş Adı",
    keyword: "Anahtar Kelime",
    completion: "Tamamlama",
    all: "Tümü",
    completedDate: "Bitiş Tarihi",
    completions: "Tamamlananlar",
    ongoing: "Devam Eden",
    formName: "Form Adı",
    sunday: "Pazar",
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarşamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
    pdfExport: "PDF Dışa Aktar",
    sendPassword: "Parolamı Gönder",
    assignedDate: "Atama Tarihi",
    events: "Olaylar",
    new: "Yeni",
    save: "Kaydet",
    formula: "Formül",
    selectDataSource: "Veri Kaynağı Seç",
    requiredField: "Zorunlu Alan",
    suffix: "Ek",
    valueColumn: "Değer Sütunu",
    showLength: "Sütunlardaki Görev Sayısını Göster",
    clearButton: "Temizle Butonu",
    allCaps: "Tüm Harfler Büyük",
    autoAsc: "Otomatik Artan",
    width: "Genişlik",
    length: "Uzunluk",
    functions: "Fonksiyonlar",
    selectElement: "Eleman Seç",
    selectColumn: "Sütun Seç",
    edit: "Düzenle",
    changeEventActions: "Olay Eylemlerini Değiştir",
    barcode: "Barkod",
    systemInfo: "Sistem Bilgisi",
    prefix: "Ön ek",
    max: "Maksimum",
    showMinMaxInfo: "Minimum ve Maksimum Bilgisini Göster",
    selectUser: "Kullanıcı Seç",
    spinButton: "Spin Butonu",
    numberIncDecButton: "Artır-Azalt Sayı Butonu",
    decimal: "Ondalık Sayı",
    numeral: "Sayı",
    wrongFormat: "Yanlış biçimi girdiniz",
    height: "Yükseklik",
    microphone: "Mikrofon",
    notifyDataReset: "Veri Sıfırlama",
    data: "Veri",
    dynamicData: "Dinamik Veri",
    manualData: "Elle Girilen Veri",
    selectDataGrid: "Veri Tablosu Seç",
    overrideUserChange: "Kullanıcı Değişikliklerini Geçersiz Kıl",
    textColumn: "Metin Sütunu",
    makeInitialValue: "İlk Değer Varsayılan Olsun",
    undo: "Geri Al",
    copy: "Kopyala",
    preview: "Önizleme",
    delete: "Sil",
    cancel: "İptal",
    languageSettings: "Dil Ayarları",
    messageSqlLinkDeleted: "Veriyi el ile eklediğiniz için SQL komut dosyası ve bağlantı adresi silinecek! Devam etmek istiyor musunuz?",
    messageSqlLinkDeletedForMenu: "Veriyi menu olarak seçtiğiniz için SQL komut dosyası ve bağlantı adresi silinecek! Devam etmek istiyor musunuz?",
    permissions: "İzinler",
    objectid: "Nesne Kimliği",
    fontSize: "Yazı Boyutu",
    fontWidth: "Yazı Genişliği",
    fontStyle: "Yazı Stili",
    underline: "Altı Çizili",
    textColor: "Metin Rengi",
    visibility: "Görünürlük",
    textAlignment: "Metin Hizalama",
    settings: "Ayarlar",
    messageUnsaved: "Değişiklikleri kaydetmeden çıkmak istediğinizden emin misiniz?",
    warning: "Uyarı!",
    userAuthorizations: "Kullanıcı Yetkilendirmeleri",
    formPreferences: "Form Tercihleri",
    formOpeningevent: "Form Açılış Olayı",
    chooseConnectionAddress: "Bağlantı Adresini Seç",
    selectPriorty: "Öncelik Seç",
    loadingEditor: "Düzenleyici Yükleniyor...",
    showAddButton: "Sütun Ekle Butonu Göster",
    update: "Güncelle",
    connectionID: "Bağlantı Kimliği",
    formPreview: "Form Önizleme",
    nameDisplayedform: "Görüntülenen Form Adı",
    formNamevariable: "Form Adı Değişkeni",
    category: "Kategori",
    formColor: "Form Rengi",
    generalForm: "Genel Form",
    saveForm: "Formu Kaydet",
    messageNeworupdate: "Varolan form üzerinde yaptığınız değişiklikler silinecek. Devam etmek istiyor musunuz?",
    messageDelete: "Formu silmek istediğinizden emin misiniz?",
    toastDeleted: "Form Silindi",
    toastError: "Üzgünüz, işlem sırasında bir hata oluştu",
    alertDifferentID: "Bu kimlik başka bir yerde kullanılmış, lütfen farklı bir kimlik belirtin.",
    confirmChanges: "Değişiklikleri Onayla",
    toastUserpermission: "Kullanıcının form tasarım izni yok.",
    toastCodeeditor: "Kod düzenleyici zaten mevcut",
    messageDeletesure: "Bu bileşen içindeki öğeler silinecek",
    messageSure: "Emin misiniz?",
    successful: "Başarılı",
    error: "Hata",
    toastFormupdate: "Form Güncellendi",
    toastMistake: "Üzgünüm, işlem sırasında bir hata oluştu",
    toastSaved: "Kaydedildi",
    toastCopied: "Kopyalandı",
    toastEmptyFormMessage: "Henüz form öğesi eklenmedi",
    toastGiveName: "Lütfen formunuza bir ad verin",
    toastGiveVariableName: "Lütfen form adı değişkenine bir ad verin",
    toastVariableNameNumberWarning: "Değişken adı rakamla başlayamaz",
    formCopy: "Formu Kopyala",
    messageButtonDelete: "Bu düğmeyi silmek istediğinizden emin misiniz?",
    noDataFound: "Veri Bulunamadı",
    language: "Dil",
    filterDatagrid: "Veri Tablosu Filtreleme",
    addManualData: "Çalışma Zamanında El İle Veri Ekle",
    dateFormat: "Tarih Biçimi",
    messageSqlLinkDelete: "Veriyi el ile eklediğiniz için SQL komut dosyası ve bağlantı adresi silinecek! Devam etmek istiyor musunuz?",
    timeOption: "Saat Seçeneği",
    defaultTodaysDate: "Varsayılan bugünün tarihi",
    dateRange: "Tarih Aralığı",
    minToday: "Minimum Bugün",
    maxToday: "Maksimum Bugün",
    dateRangeDay: "Tarih Aralığı (Gün)",
    enterDateRangeValue: "Tarih aralığı değerini girin",
    imageIdColumn: "Kimlik Sütunu",
    statusSettings: "Durum Ayarları",
    active: "Aktif",
    passive: "Pasif",
    enable: "Etkinleştir",
    disable: "Devre Dışı Bırak",
    visible: "Görünür",
    invisible: "Görünmez",
    items: "Öğeler",
    showItems: "Öğeleri Göster",
    array: "Dizi",
    letLabelAppear: "Etiket Göster",
    add: "Ekle",
    horizontal: "Yatay",
    vertical: "Dikey",
    text: "Metin",
    alertFillRequiredFields: "Lütfen zorunlu alanları doldurun",
    alertSignatureField: "İmza alanı boş olamaz",
    messageApproveTransaction: "İşlemi onaylıyor musunuz?",
    button: "Düğme",
    reasonforRefusal: "Red Nedeni",
    enterReasonRejection: "Red Nedenini Girin",
    reject: "Reddet",
    addDeleteButton: "Düğme Ekle/Sil",
        
        
    showBorder: "Sınır Gözüksün",
    buttons: "Düğmeler",
    clean: "Temizle",
    buttonSettings: "Düğme Ayarları",
    saveLocation: "Kayıt Konumu",
    paymentButton: "Ödeme Düğmesi",
    paymentProvider: "Ödeme Sağlayıcısı",
    price: "Fiyat",
    numberOfInstallments: "Taksit Sayısı",
    descriptionField: "Açıklama Alanı",
    current: "Mevcut",
    currentForm: "Mevcut Form",
    name: "Ad",
    surname: "Soyad",
    country: "Ülke",
    city: "Şehir",
    address: "Adres",
    authorizations: "Yetkilendirmeler",
    users: "Kullanıcılar",
    user: "Kullanıcı",
    givePermission: "Yetkilendir",
    groups: "Gruplar",
    group: "Grup",
    saveandSend: "Kaydet ve Gönder",
    approve: "Onayla",
    sendBack: "Geri Gönder",
    deslineAndEnd: "Reddet ve Bitir",
    complete: "Tamamlandı",
    blue: "Mavi",
    gray: "Gri",
    green: "Yeşil",
    red: "Kırmızı",
    left: "Sol",
    right: "Sağ",
    color: "Renk",
    symbol: "Sembol",
    position: "Konum",
    action: "Eylem",
    total: "Toplam",
    filePath: "Dosya Yolu",
    fileName: "Dosya Adı",
    fileUpload: "Dosya Yükle",
    choose: "Seç",
    updateData: "Veriyi Güncelle",
    show: "Göster",
    columns: "Sütunlar",
    changeEvent: "Olayı Değiştir",
    columnSettings: "Sütun Ayarları",
    columnName: "Sütun Adı",
    deleteColumn: "Sütunu Sil",
    columnSqlScripts: "Sütun SQL Komutları",
    imageNameColumn: "Dosya Adı Sütunu",
    columnLanguageSetting: "Sütun Dil Ayarı",
    dataSource: "Veri Kaynağı",
    filter: "Filtre",
    reset: "Sıfırla",
    column: "Sütun",
    image: "Resim",
    searchLocation: "Konum Ara...",
    draggable: "Taşınabilir",
    usersCanChange: "Kullanıcılar Değiştirebilir",
    multiMarker: "Çoklu İşaretçi",
    markerLimit: "İşaretçi Sınırı (0: Sınırsız)",
    meetingType: "Toplantı Türü",
    meetingName: "Toplantı Adı",
    loadingFiles: "Dosyalar Yükleniyor...",
    fileSizeExceeded: "Dosya boyutu aşıldı",
    filesLoaded: "Dosyalar Yüklendi",
    condition: "Koşul",
    value: "Değer",
    
    
  
    connectionAddress: "Bağlantı Adresi",
    imageTypeColumn: "Dosya Türü Sütunu",
    visibleColumn: "Görünür Sütun",
    selectionMode: "Seçim Modu",
    selectMode: "Seçim Modu",
    datagridFiltering: "Veri Tablosu Filtreleme",
    imageDataColumn: "Dosya Sütunu",
    selectDataColumn: "Veri Tablosu Sütunu Seç",
    dataFormat: "Veri Biçimi",
    photoPreview: "Fotoğraf Önizlemesi",
    videoPreview: "Video Önizlemesi",
    displayPhoto: "Fotoğrafı Göster",
    displayVideo: "Videoyu Göster",
    imageColumn: "Resim Sütunu",
    videoColumn: "Video Sütunu",
    columnNameAppear: "Görünmesi Gereken Sütun Adı",
    selected: "Seçilmiş",
    inStock: "Stokta Var",
    messageSave: "Değişikliklerinizi kaydetmek istediğinizden emin misiniz?",
    gallery: "Galeri",
    dollar: "dolar",
    sterling: "sterlin",
    underPhoto: "Fotoğrafın Altında",
    topPhoto: "Fotoğraf Üstü",
    byCategory: "Kategoriye Göre",
    dashboardShowHide: "Göster/Gizle Gösterge Tablosu",
    showDetail: "Detayı Görüntüle",
    hideDetail: "Detayı Gizle",
    decrease: "Azalt",
    increase: "Artır",
    watch: "izle",
    watchMovie: "Film İzle",
    noRecordsFound: "Kayıt Bulunamadı!",
    appearance: "Görünüm",
    numberofColumns: "Sütun Sayısı",
    pagingRecords: "Sayfalama Kayıtları",
    detailLocation: "Detay Konumu",
    hideImages: "Resimleri Gizle",
    quantityCategoryDisable: "Miktar Arttığında Kategoriyi Devre Dışı Bırak",
    general: "Genel",
    idColumn: "Kimlik Sütunu",
    availableQuantity: "Kullanılabilir Miktar",
    categoryColumn: "Kategori Sütunu",
    photoColumn: "Fotoğraf Sütunu",
    titleColumn: "Başlık Sütunu",
    priceColumn: "Fiyat Sütunu",
    updatablePrice: "Güncellenebilir Fiyat",
    
  
    videoGallery: "Video Galerisi",
    uniqueComponent: "Tekil Bileşen",
    showVariant: "Varyantı Göster",
    transferTotalAmount: "Toplam Tutarı Aktar",
    title: "Başlık",
    hide: "Gizle",
    detail: "Detay",
    priceQuery: "Fiyat Sorgusu",
    quantityQuery: "Miktar Sorgusu",
    uniqueComponentError: "Tekil Bileşen Hatası",
    joinColumn: "Birleştirme Sütunu",
    nameColumn: "Ad Sütunu",
    variantScript: "Varyant Betiği",
    variantImages: "Varyant Resimleri",
    variantJoinColumn: "Varyant Birleştirme Sütunu",
    showAll: "Hepsini Göster",
    showSelected: "Seçilenleri Göster",
    showByCategory: "Kategoriye Göre Göster",
    showInStock: "Stoktakileri Göster",
    notYetAdded: "Henüz Eklenmedi",
    added: "Eklendi",
    startingDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    themeFeatures: "Tema Özellikleri",
    menu: "Menü",
    selectMenu: "Menü Seç",
    selectSubmenu: "Alt Menü Seç",
    selectMenuBrowser: "Menü Tarayıcı Seç",
    nameElement: "Ad Elemanı",
    subject: "Konu",
    picture: "Resim",
    pictureElement: "Resim Elemanı",
    startingDateElement: "Başlangıç Tarihi Elemanı",
    endDateElement: "Bitiş Tarihi Elemanı",
    usersCanEdit: "Kullanıcılar Düzenleyebilir",
    thisPropertyCant: "Bu özellik veriler SQL'den alınıyorsa kullanılamaz",
    calendarDisplay: "Takvim Görüntüleme Özellikleri",
    monthly: "Aylık",
    generalDisplayLunarDays: "Ay Günlerinin Genel Görünümü",
    agenda: "Gündem",
    listofWeeklyEvents: "Haftalık Etkinlik Listesi",
    weekly: "Haftalık",
    weekend: "Hafta Sonu",
    generalDisplayWeekDays: "Hafta Günlerinin Genel Görünümü",
    showWeekendDaysWhileWeekdays: "Hafta Günlerini Gösterirken Hafta Sonu Günlerini Göster",
    daily: "Günlük",
    listofDailyEvents: "Günlük Etkinlik Listesi",
    startTime: "Başlama Saati",
    endTime: "Bitiş Saati",
    changeDefaultColor: "Varsayılan Renkleri Değiştir",
    weekdayColor: "Hafta İçi Gün Rengi",
    weekendColor: "Hafta Sonu Gün Rengi",
    eventColor: "Etkinlik Rengi",
    groupBySql: "SQL'ye Göre Grupla",
    groupColumnName: "Grup Sütun Adı",
    assignedColumnName: "Atanan Sütun Adı",
    taskPriortiyColumnName: "Görev Öncelik Sütun Adı",
    taskSubjectColumnName: "Görev Konu Sütun Adı",
    taskDetailColumnName: "Görev Detay Sütun Adı",
    taskQuery: "Görev Sorgusu",
    taskKeyExpression: "Görev Anahtar İfadesi",
    taskTitleExpression: "Görev Başlık İfadesi",
    taskStartDateExpression: "Görev Başlangıç Tarihi İfadesi",
    taskEndDateName: "Görev Bitiş Tarihi Adı",
    taskParentExpression: "Görev Üst İfadesi",
    taskProgressExpression: "Görev İlerleme İfadesi",
    taskColorExpression: "Görev Renk İfadesi",
    taskConnections: "Görev Bağlantıları",

    
    diagramClear: "Diyagramı temizlemek istediğinize emin misiniz? Bu işlem geri alınamaz.",
    uniqueComponentMenuCloneWarning: "Lütfen benzersiz bileşeni kopyalamadan önce değiştirin.",
    filesLoading: "Dosyalar yükleniyor...",
    couldnotbeLoaded: "yüklenemedi.",
    fileSizeLimit: "Dosya Boyutu Sınırı",
    filesUploaded: "Dosyalar yüklendi",
    filesWillDeleted: "Dosyalar silinecek!",
    filesSureDelete: "Dosyaları silmek istediğinize emin misiniz?",
    fileDelete: "Dosyayı silmek istiyor musunuz?",
    uploadedPhotos: "Yüklenen Fotoğraflar",
    maxSize: "Maksimum Boyut",
    center: "Orta",
    size: "Boyut",
    canItBeUpdated: "Kurulumdan sonra güncellenebilir mi?",
    uploadedFiles: "Yüklenen Dosyalar",
    type: "Tür",
    showdeleteOldData: "Eski Verilerin Silme Seçenekleri",
    videoBrowser: "Tarayıcınız video etiketini desteklemiyor.",
    deleted: "Silindi",
    theseComponentElementsDeleted: "Bu bileşen öğeleri silinecek.",
    invalidTarget: "Geçersiz hedef",
    ridge: "Düz Çizgi",
    dotted: "Nokta",
    dashed: "Çizgili",
    addRowColumn: "Satır/Sütun Ekle",
    dataPagingShow: "Verileri Sayfalara Getir",
    borderType: "Kenarlık Türü",
    maxCharacters: "Maksimum Karakter Sayısı",
    edgeThickness: "Kenar Kalınlığı",
    lineSpacing: "Satır Aralığı",
    settingsRow: "Sütun Satır/Sütun Ayarları",
    addRow: "Satır Ekle",
    deleteRow: "Satır Sil",
    rowPermission: "Satır İzinleri",
    addTabs: "Sekme Ekle",
    deletePanel: "Paneli Sil",
    tabPermission: "Sekme İzni",
    addPanel: "Panel Ekle",
    panelPermission: "Panel İzinleri",
    backgroundColor: "Arka Plan Rengi",
    saveAndCloseChildForm: "Yeni bir form açılırken bu alt formu kaydedip kapatılsın mı?",
    icon: "Sembol",
    selectForm: "Form Seç",
    fontColor: "Yazı Rengi",
    minCharacters: "Minimum Karakter Sayısı",
    generalCategory: "Genel Kategori",
    removeRow: "Kaydı silmek istediğinize emin misiniz?",
    send: "Gönder",
    declineFinish: "Reddet ve Bitir",
    finish: "Bitir",
    noData: "Veri Yok",
    createEditMenu: "Menü Oluştur/Düzenle",
    addNewBreakdown: "Yeni Ayrıntı Ekle",
    addForm: "Form Ekle",
    addBottomBreak: "Alt Ayrıntı Ekle",
    addNode: "Düğüm Ekle",
    topCategory: "Üst Kategori",
    explanation: "Açıklama",
    browserTitle: "Tarayıcı Başlığı",
    formTitle: "Form Başlığı",
    addSqlColumn: "Sql Sütunu Ekle",
    formBg: "Form Arkaplanı",
    codeEditor: "Kod Editörü",
    authorityType: "Yetki Türü",
    viewOnly: "Yalnızca Görüntüle",
    dark: "Koyu",
    white: "Beyaz",
    hightBlack: "Siyah",
    run: "Çalıştır",
    rowColor: "Satır Rengi",
    operator: "Operatör",
    flowOfForm: "Form Akışı",
    columnWidth: "Sütun Genişliği",
    alignLeft: "Sola Hizala",
    alignRight: "Sağa Hizala",
    alignButton: "Düğmeyi Hizala",
    imageTableNameColumn: "Tablo Adı",
    uploadBg: "Arka Plan Yükle",
    selectFile: "Dosya Seç",
    dropFile: "Dosyayı buraya bırak",
    alwaysRoundUp: "Her Zaman Yuvarla Yukarı",
    alwaysRoundBot: "Her Zaman Yuvarla Aşağı",
    default: "Varsayılan",
    deleteOldDataTable: "Tablonun eski verilerini silinsin mi?",
    deleteOldData: "Eski veriler silinsin mi?",
    integer: "Tam Sayı",
    double: "Ondalık Sayı",
    pleaseFillFields: "Lütfen zorunlu alanları doldurun.",
    toastColumnNameNumber: "Sütun adı sayı ile başlayamaz",
    areYouSure: "Emin misiniz?",

    
    columnDeleted: "Sütun silinecek",
    allColumnDeleted: "Tüm Sütunlar Silinecek",
    idCantStartNumber: "Kimlik sayıyla başlayamaz",
    uploadFile: "Dosya Yükle",
    fileViewer: "Dosya Görüntüleyici",
    ok: "Tamam",
    map: "Harita",
    editPanel: "Paneli Düzenle",
    filterColumnName: "Filtre Sütun Adı",
    hintExamples: "ToplamMiktar, örnek:Maas",
    columnLanguageSettings: "Sütun Dil Ayarları",
    fileColumn: "Dosya Sütunu",
    fileNameColumn: "Dosya Adı Sütunu",
    extensionColumn: "Uzantı Sütunu",
    multipleSelection: "Çoklu Seçim",
    addSelectionsRows: "Seçimleri satır olarak ekle",
    mapSettings: "Harita Ayarları",
    formatAndFeatures: "Biçim ve Özellikler",
    enterDateRange: "Tarih aralığı değerini girin",
    filesCanUploadedUsers: "Kullanıcılar dosya yükleyebilir",
    barcodeColumn: "Barkod Sütunu",
    autoAscend: "Otomatik Artış",
    roundType: "Yuvarlama Türü",
    font: "Yazı Tipi",
    columnProcess: "Sütun İşlemi",
    exportColumnTotal: "Toplam sütunu dışa aktar",
    outsideElementDisabled: "Harici Elemanları Devre Dışı Bırak",
    chat: "Sohbet",
    languages: "Diller",
    display: "Resim",
    enterYourComment: "Yorumunuzu girin",
    comment: "Yorumlar",
    fileUploaded: "Dosya yüklendi",
    pdfViewIsButton: "Düğme Olarak Göster",
    takeScreenshot: "Ekran Görüntüsü Al",
    alertText: "Uyarı Metni",
    clearButtonVisible: "Temizle Düğmesini Göster",
    allText: "Tümü",
    letter: "Sadece Harf",
    letterandnumber: "Harf ve Sayı",
    letterandsymbol: "Harf ve Sembol",
    numberandsymbol: "Sayı ve Sembol",
    showIDColumn: "Kimlik Sütununu Göster",
    showCopyButton: "Kopyala Düğmesini Göster",
    showDeleteButton: "Sil Düğmesini Göster",
    showCheckboxButton: "Onay Kutusu Düğmesini Göster",
    autoRowHeight: "Otomatik Satır Yüksekliği",
    exportImport: "Form İçe/Dışa Aktar",
    export: "Dışa Aktar",
    import: "İçe Aktar",
    toastImport: "Form İçe Aktarma Tamamlandı.",
    warningApproveImport: "Gerekli ayarları yaptıktan sonra işlemi onaylayın. Formu içe aktarmak, şu anda açık olan formu kapatır",
    defaultTodaysDateWithTime: "Varsayılan bugünkü tarih ve saat",
    filterVisible: "Sütun Filtresini Göster",
    updatetableMaxQuantity: "Maksimum Güncelleme Miktarı",
    hideQuantity: "Miktar seçimini gizle",
    disableQuantityTextbox: "Miktar yazma kutusunu devre dışı bırak",
    multiplesOfQuantity: "Miktarın katlarını artır/azalt",
    justnumber: "Sadece Sayı",
    paymentHourWarning: "Kontrol saati dışında ödeme yapılamaz.",
    addColumn: "Sütun Ekle",
    mailSettings: "E-posta Seçenekleri",
    newTaskMailDetail: "Yeni bir görev atanırken gönderilecek posta metni",
    updateTaskMailDetail: "Görev düzenlendiğinde gönderilecek posta metni",
    taskOnDropMail: "Görev taşındığında gönderilecek posta metni",
    selectSMTP: "SMTP Adresi Seç",
    taskCreator: "Görevi Atayan Kişi",
    taskEndDate: "Görev Bitiş Tarihi",
    tags: "Etiketler",
    taskStartDate: "Görev Başlangıç Tarihi",
    taskAssigned: "Görev Atanan Kişi",
    taskDetail: "Görev Ayrıntısı",
    taskSubject: "Görev Başlığı",
    taskPri: "Görev Önceliği",
    taskEdit: "Görev Güncelleme",
    addTask: "Görev Ekle",

    
    taskStatus: "Görev Durumu",
    excelExportSettings: "Excel Dışa Aktarma Ayarları",
    excelExportRequiredColor: "Zorunlu Alan Sütun Rengi",
    allDataExcelExport: "Veriyi Excel Olarak Dışa Aktar",
    warningApproveImportReport: "Gerekli düzenlemeleri yaptıktan sonra işlemi onaylayın. Raporu içe aktarmak, şu anda açık olan raporu kapatır.",
    reportName: "Rapor Adı",
    emptyReportName: "Lütfen Sağ Taraftan Rapor Adı Ekleyiniz.",
    old: "eski",
    upperNode: "Üst Kırılma",
    pdfViewStaticPdf: "Sabit Dosya Olarak Göster",
    actions: "Eylemler",
    loading: "Yükleniyor",
    exportDataToExcell: "Tüm veriyi Excel'e aktar",
    showRefreshIcon: "Yenileme Düğmesi",
    headerFilter: "Başlık Filtresi",
    filterRowVisible: "Arama Kutusu Sütunda Görünür",
    password: "Parola",
    categories: "Kategoriler",
    pressEnter: "Enter Tuşuna Basın",
    historyColumnValue: "Veri Geçmişi Sütunu",
    hideMobile: "Mobil Cihazlarda Gizle",
    align: "Hizalama",
    theme: "Tema",
    info: "Bilgi",
    showTitle: "Başlığı Göster",
    purple: "Mor",
    brown: "Kahverengi",
    yellow: "Sarı",
    turnOnComments: "Yorumları Aç",
    showContent: "İçeriği Göster",
    canBeHidden: "Gizlenebilir",
    allowEdit: "Düzenlemeye İzin Ver",
    allowDelete: "Silme İzni Ver",
    allowAdd: "Ekleme İzni Ver",
    allowCopy: "Kopyalama İzni Ver",
    toastDeleteErrorMessage: "Bu bileşen Form Tercih komut dosyalarında kullanılıyor. Silinemez.",
    formOpeningMultiElementEvent: "Çoklu Eleman Oluşturma Olayı",
    resetDatagridLocalSettings: "Datagrid Yerel Ayarlarını Sıfırla",
    loginP1:"River kurumsal hafızanız olarak organizasyon veya kurumunuzun geçmişteki deneyimleri, başarıları, başarısızlıkları, öğrenilen dersler,kültürü ve değerleri gibi bilgileri içeren, gelecekteki kararlar ve eylemler için bir referans kaynağı olarak kullanılabilecek bütünsel bir bellek sistemi sağlar.Kurumsal hafıza, organizasyonun tarihini, iş süreçlerini, çalışanlarının bilgi birikimini,müşteri deneyimlerini, ürün ve hizmetlerinin gelişimini ve daha birçok bilgiyi kapsar.Bu bilgiler, kurumun gelecekteki hedef ve stratejileri için yol gösterici olacaktır.",
    loginP2 : "River, web ve gelişmiş native mobil uygulama destekli yapısı ile işletmenizin iş süreçlerini daha verimli ve etkili bir şekilde yürütmesine yardımcı olur. Erişmeniz gereken her şey size bilgisayar, tablet ya da akıllı telefonunuz kadar yakın.",
    loginConnect :"Bağlan",
    loginText: "Giriş",
    loginRightMore:"Dahası",
    loginHeaderP1:"Kurumsal Hafızanız",
    loginHeaderP2: "Mobilite",
    loginHeaderP3: "Grafiksel Güç",
    kanbanaddTasks :"Kanban Task Ekleme",
    taskstatusDesciption:"Kanban görevinin atanacağı sütunun isminin görüntülenmesini sağlamaktadır.",
    tasksubjectDescription:"Kanban görevine ait başlık girilmesini sağlamaktadır. Kanban başlığı bir zorunlu alandır.",
    taskassignedToTask:"Göreve Eklenen Gruplar",
    taskassignedTaskDescription: "Kanban görevine atanacak grupların seçilmesini sağlamaktadır.",
    addedForTasks:"Görev İçin Eklenenler",
    taskAssignedInfoDescription: "Görev İçin Bilgilendirilecek Kişiler",

    assignedInfo :"Bilgilendirilecek kişiler",
    groupInfo :"Görev İçin Bilgilendirilecek Gruplar",
    sortEnd	:"Bitiş Tarihine Göre Sırala",
    sender	:"Gönderen",
    forward	:"İlet",
    attachments	:"Ekler",
    taskdetailDescription	:"Kanban görevine ait detayların girilmesini sağlamaktadır. Kanban detayı bir zorunlu alandır.",
    taskassignedDescription	:"Kanban görevine atanacak kullanıcıların seçilmesini sağlamaktadır. Kanbanda en az bir kullanıcı atanması zorunludur.",
    taskstartdateDescription:	"Kanban görevinin başlangıç tarihinin seçilmesini sağlamaktadır. Kanbanda başlangıç tarihi otomatik olarak görevin oluşturulma zamanını almaktadır.",
    taskenddateDescription:	"Kanban görevinin bitiş tarihinin seçilmesini sağlamaktadır. Kanbanda görev bitiş tarihi otomatik olarak görevin oluşturulma zamanınından bir hafta sonrasını almaktadır.",
    taskpriDescription:	"Kanban görevinin öncelik durumunu seçilmesini sağlamaktadır. Kanbanda görev bitiş tarihi otomatik olarak görevin oluşturulma zamanınından bir hafta sonrasını almaktadır.",
    tagsDescription:	"Kanban görevine ait etiketlerin eklenmesini sağlamaktadır. Etiketler virgül ile eklendikçe birbirlerinden ayrılmaktadır.",
    outlookDescription	:"Outlook a eklensin seçeneği açıkken yapılan görev eklemelerinde outlook hesabınıza giriş yapıp otomatik olarak takvime görev eklenmektedir.",
    taskAssInfoDescription:	"Göreve atanmayan ancak bilgisinin olması istenilen kişiler için kullanılan alandır. Bilgilendirmek için eklenen kişiler görevler üzerinde işlem yapamazlar.",
    receiver:	"Alıcı",
    draft:	"Taslak",
    spam:	"Gereksiz E-Posta",
    filterstate	:"Duruma Göre Filtrele",
    checkForspelling:	"Yazım Hatalarını Kontrol Et",
    emailToForward:	"İletilecek E-Posta", 
    lowImpMails	:"Düşük Önemli Mailler", 
    normalImpMails:	"Normal Önemli Mailler ",
    highImpEmails:	"Yüksek Önemli Mailler", 
    showReadMails:	"Okunmuş Mailleri Göster", 
    groupinfoDescription :"Göreve atanmayan ancak bilgisinin olması istenilen gruplar için kullanılan alandır. Bilgilendirmek için eklenen gruplara ait kişiler görevler üzerinde işlem yapamazlar.",
    timeoutMission:	"Görev Bitmeden Uyarıya Başlanacak Saat",
    timeoutmissionDescription:	"Kanban görevinin bitiş zamanına yaklaşıldığında uyarıya başlanılacak zamanı belirtmek için kullanılmaktadır",
    taskperiodDescription:	"Kanban görevinin bitiş tarihine yaklaşıldığında periyodik olarak hatırlatma süresini belirtmek için kullanılmaktadır.",
    taskProgress:	"Görev İlerleme Durumu",
    taskProgressDescription	:"Kanban görevine ait ilerleme durumunu belirtmek için kullanılmaktadır. İlerleme durumları Gantt şeması üzerinden görüntülenebilmektedir.",
    updatekanbanTask	:"Kanban Task Güncelleme",
    taskcreatorDescription:	"Kanban görevi oluşturan kişinin görüntülenmesini sağlamaktadır.",
    uploadfileDescription:	"Kanban görevine dosya yüklemek için kullanılmaktadır. Dosya veya resimler DMS üzerine kayıt olmaktadır.",
    infoPersons:	"Bilgilendirilecek Kişiler",
    warningtime:	"Uyarı Zamanı",
    taskPeriod	:"Görev Periyodu",
    taskProgresSatus:	"Görev İlerleme Durumu",
    makeTask:	"Görevi Oluşturan Kişi",
    addedforTasks:	"Görev İçin Eklenenler",
    outlookIntegration:	"Outlook Entegrasyonu",
    loginoutlook:	"Outlook Takvimine Giriş Yap",
    fetchoutlook:	"Outlook Takvimindeki Verileri Getir",
    progressStatus:	"İlerleme Durumu",
    taskColors	:"Görev Renkleri",
    addcalendarTask:	"Takvime Görev Ekleme",
    updatetaskCalendar:	"Takvim Task Güncelleme",
    statusComplete:	"Tamamlanma Durumu Filtreleme ",
    priorityFilter:	"Öncelik Filtreleme",
    columnFilter	:"Sütun Filtreleme",
    assignPerson	:"Atanan Kişiler",
    assignGroup	:"Atanan Gruplar",
    noTask	:"Hiçbir Görev Bulunamadı",
    resetSort:	"Sıralamayı Sıfırla",
    sortASC:	"Sırala A - Z",
    sortDESC:	"Sırala Z - A",
    sortAppointment:	"Atanma Tarihine Göre Sırala",
    trash	:"Çöp Kutusu",
    showOlderMail	:"Daha Eski Mailleri Göster",
    mailNotFound	:"Mail Bulunamadı",
    sendNewMail	:"Yeni Mail Gönder",
    showUnreadMails:	"Okunmamış Mailleri Göster ",
    updateColumnMail:	"Sütuna Görev Eklendiğinde Kişiye Gidecek Mesaj ",
    addTaskToOutlook:	"Görev Outlooka da eklensin ",
    enterYourMessage:	"Mesajınızı Giriniz ",
    reminder:	"Hatırlatıcı ",
    determinedToTask:	"Görev için belirlenen süre ",
    remainingTime:	"Kalan süre ",
    hours:  "Saat",
    withoutPriority:	"Önceliksiz" ,
    lowPri:	"Düşük Öncelikli ",
    normalPri:	"Orta Öncelikli" ,
    highPri:	"Yüksek Öncelikli ",
    showAllTasks:	"Hepsini Göster ",
    showIncompleteTasks:	"Tamamlanmamışları Göster" ,
    showCompeletedTasks	:"Tamamlananları Göster ",
    reportDesigner:	"Rapor Tasarımcısı ",
    fileManagementSystem:	" Dosya Yönetim Sistemi ",
    formDesigner:	"Form Tasarımcısı ",
    boardDesigner:	" Pano Tasarımcısı",
    themeSettings:	 "Tema Özellikleri",
    social	: "Sosyal",
    profile:	" Profil",
    contacts:	" Kişiler",
    files:	" Dosyalar",
    pages:	 "Sayfalar",
    noResultsFound:	" Sonuç Bulunamadı",
    processes:	 "Süreçler",
    filterByStatus:	 "Duruma Göre Filtrele ",
    filterByPri:	 "Önceliğe Göre Filtrele",
    filterByCol:	 "Sütuna Göre Filtrele",
    todoAddTasks:	"To-Do Task Ekleme",
    addLabel:	"Etiket Giriniz",
    showWithSubTasks:	"Alt Görevi Olanları Göster" ,
    riverBoard	:"River Panolar" ,
    notify	:"Bildirimler" ,
    likedYourPost:	"Gönderinizi Beğendi ",
    likedYourComment:	"Yorumunuzu Beğendi ",
    systemNotify:	"Sistem Bildirimleri ",
    readAllNotify	:"Tum Bildirimleri Oku ",
    recommendedUsers:	"Önerilen Kişiler",
    suggestedPosts:	"Önerilen Gönderiler",
    sPostReposted:	"Gönderisi Yeniden Paylaşıldı",
    shareSomething:	"Birşeyler Paylaş",
    updatePost:	"Gönderiyi Güncelle",
    ifYouDoNotSelectGroup:	"Grup seçmezseniz gönderinizi herkes görebilir",
    addComment:	"Yorum Ekle",
    sendComment	:"Yorum Gönder",
    areYouSureYouWantToDeleteThisPost:	"Gönderiyi Silmek İstediğinizden Emin misiniz",
    yes	:"Evet",
    no	:"Hayır",
    deletePost:	"Gönderiyi Sil",
    postSuccesfullyDeleted	:"Gönderi başarıyla silindi",
    deleteComment	:"Yorum Sil",
    areYouSureYouWantToDeleteThisComment:	"Bu yorumu silmek istediğinizden emin misiniz",
    commentSuccesfullyDeleted:	"Yorum Başarıyla Silindi",
    sharePost:	"Gönderi Paylaş",
    areYouSureYouWantToShareThisPost:	"Bu gönderiyi paylaşmak istediğinizden emin misiniz",
    repostedSuccessfully:	"Başarıyla Yeniden Paylaşıldı",
    upload	:"Yükle",
    refresh	:"Yenile",
    allowedExtentions:	"İzin verilen uzantılar: JPG, GIF veya PNG. Maksimum dosya boyutu",
    systemLandingPagePreference:"	Açılış sayfası Tercihi",
    allownonadminuserstosearchforotherusers:	"Admin olmayan kullanıcılar diğer kullanıcıları arayabilsin",
    passwordChange:	"Şifre Değişikliği",
    newPassword	:"Yeni Şifre",
    retypethenewpassword:	"Yeni şifreyi tekrar yazınız",
    notifications	:"Bildirimler",
    showWhoLikesYourPost:	"Postlarınızı beğenenleri göster",
    showWhoLikesYourComment:	"Yorumlarınızı beğenenleri göster",
    aboutMe	:"Hakkımda",
    bio	:"Biyografi",
    dateOfBirth:	"Doğum Tarihi",
    website:	"Web sitesi",
    phone:	"Telefon",
    saveChanges	:"Değişiklikleri Kaydet",
    username	:"Kullanıcı adı",
    content	:"İçerik",
    projectManagement:	"Proje Yönetimi",
    addPage:	"Sayfa Ekle" ,
    addFolder:	"Klasör Ekle ",
    showUsers:	"Görebilecek Kullanıcılar ",
    showGroups:	"Görebilecek Gruplar" ,
    readonlyUsers:	"Okuyabilecek Kullanıcılar ",
    readonlyGroups:	"Okuyabilecek Gruplar ",
    columnHeight:	"Sütun Yüksekliği",
    showKanbanCount:	"Kanban Adet Bilgisini Göster"  ,
    kanbanSettings:	"Kanban Ayarları ",
    gantSettings:	"Gant Ayarları ",
    schedulerSettings:	"Takvim Ayarları ",
    showRowLines:	"Satır Çizgilerini göster ",
    activateOutlookConnection:	"Outlook Bağlantısını Etkinleştir ",
    selectOutlookColumn:	"Outlook sütununu seçiniz ",
    selectSMTPPopoverText:	"Kanban, Gantt, Takvim veya To-Do bileşenlerinde kullanılacak mail SMTP seçimi yapılmaktadır. ",
    menuFilePathId:	"River Menu klasörü id",
    recycleBinPathName:	"River Çöp Kutusu Klasörü Adı",
    recycleBinPathId:	"River Çop Kutusu Klasörü Id",
    fileNameChangedMessage:	"Dosya ismi başarıyla değiştirildi.",
    fileNameChangedErrorMessage	:"Dosya ismi değiştirilemedi.",
    fileCreatedMessage:	"Dosya başarıyla oluşturuldu.",
    newTaskMailDetailPopoverText:	"Kanban, Gantt, Takvim veya To-Do bileşenlerine yeni bir görev atandığında, atanan kullanıcılara gönderilecek olan otomatik mail içerisine eklenecek mail içeriğinin eklendiği kısım. ",
    taskOnDropMailPopoverText:	"Kanban, Gantt, Takvim veya To-Do bileşenlerine bir görevin bulunduğu sütununun değiştirilmesiyle birlikte gönderilecek olan otomatik mail içerisine eklenecek mail içeriğinin eklendiği kısım. ",
    createdBy	:"Oluşturan",
    updatedBy	:"Güncelleyen",
    createdDate	:"Oluşturulma Tarihi",
    updatedDate	:"Güncellenme Tarihi",
    folderPermissionSettings:	"Dosyası yetki ayarları",
    quantity:	"Adet",
    fileManagementPermissionSettings:	"Dosya Yönetimi Yetki Ayarları",
    fileNotFoundErrorText:	"Aradığınız kriterlere uygun dosya bulunamadı.",
    updateTaskMailDetailPopoverText:	"Kanban, Gantt, Takvim veya To-Do bileşenlerine ilgili görevde değişiklik yapıldığında, atanan kullanıcılara gönderilecek olan otomatik mail içerisine eklenecek mail içeriğinin eklendiği kısım. ",
    selectOutlookColumnPopoverText:	"Outlook üzerinden eklenecek olan görevlerin hangi sütuna dahil edileceği seçilmektedir. ",
    createFolder:	"Dosya Oluştur",
    versionHistory:	"Sürüm Geçmişi",
    deletePermanently:	"Kalıcı Olarak sil",
    recover:"Kurtar",
    groupPermissions:	"Grup Yetkileri",
    allowUpload:	"Dosya Yüklemeye izin ver",
    allowView:	"Dosya görüntülemeye izin ver",
    allowDownload:	"Dosya indirmeye izin ver",
    permissionType:	"İzin Tipi",
    applyToSubFolders:	"Tüm alt klasörlere uygula",
    newFolderName:	"Yeni Dosya Adı",
    tagTips	:"Etiketlemeler başında # işareti ile yapılmalıdır. İçerisinde sadece sayı ve numara bulunabilir",
    addAsNewVersion:	"Yeni bir sürüm olarak yükle",
    addAndChangeName:"İsmini değiştirerek yükle",
    skip:	"Atla",
    fileExistError:	"Hedef klasörde aynı isimde dosya mevcut",
    processFileShow:	"River Süreç Klasörünü göster",
    processFilePathName:	"River Süreç Klasörü ismi",
    processFilePathId:	"River Süreç klasörü id",
    menuFileShow:	"River Menü Klasörünü göster",
    menuFilePathName:	"River Menu Klasörü ismi",
    fileCreatedErrorMessage:	"Dosya oluşturma işlemi başarısız.",
    fileDeletedessage:	"Dosya silme işlemi tamamlandı.",
    fileDeletedErrorMessage	:"Dosya silme işlemi başarısız.",
    fileMovedMessage:	"Dosya taşıma işlemi tamamlandı.",
    fileMovedErrorMessage:	"Dosya taşıma işlemi başarısız.",
    fileCopyMessage	:"Dosya kopyalama işlemi tamamlandı.",
    fileCopyErrorMessage:	"Dosya kopyalama işlemi başarısız.",
    fileDownloadMessage:	"Dosya indirme işlemi tamamlandı.",
    fileDownloadErrorMessage:	"Dosya indirme işlemi başarısız.",
    fileSizeShowErrorMessage:	"Dosya boyutu büyük olduğu için görüntüleyemiyoruz. Lütfen dosyayı indirin",
    unsupportedFile	:"Desteklenmeyen dosya",
    fileNameError:	"Bu dosya adında bir dosya mevcut farklı bir ad ile tekrar deneyiniz",
    fileGivePermissionMessage	:"Dosya yetkilendirmesi başarıyla tamamlandı.",
    fileGivePermissionErrorMessage:	"Dosya yetkilendirmesinde hata oluştu. Kayıt başarısız.",
    groupName:	"Grup Adı",
    createGroup	:"Grup Oluştur",
    conversation	:"Konuşmalar",
    startConversation:	"Konuşma Başlat",
    userNotFound:	"Kişi bulunamadı",
    chatNotFound:	"Sohbet bulunamadı",
    themeTypeLight:	"Aydınlık",
    themeTypeBorderedLight:	"Çerçeveli",
    themeHalfDark:	"Yarı Koyu",
    themeDark:	"Koyu",
    themeRouteAnimation:	"Geçiş Animasyonu",
    themeRouteAnimationFadeInLeft:	"Soldan Solma",
    themeRouteAnimationZoomIn:	"Yakınlaştır",
    themeRouteAnimationFadeIn:	"Solma",
    themeRouteAnimationNone:	"Yok",
    themeMenuType	:"Menü Tipi",
    themeMenuTypeHorizontal:	"Yatay",
    themeMenuTypeVertical:	"Dikey",
        themeMenuCollapse: "Dar Menü",
        themeMenuHidden:"Gizli Menü",
    themeNavbarColor:	"Gezinme Çubuğu Rengi",
    themeNavbarType	:"Gezinme Çubuğu Türü",
    themeSticky	:"Yapışkan",
    themeStatic	:"Statik",
    themeFloating:	"Yüzer",
    themeHidden	:"Gizli",
    themeFooterType	:"Alt Bilgi Türü",
    catalog	:"Katalog",
    riverBoards	:"River Panolar",
    clipboardDesigner:	"Pano Tasarımcısı",
    desingMode:	"Tasarım Modundasınız",
    editPage:	"Sayfayı Düzenle",
    rename:	"Yeniden Adlandır",
    exportToPdf	:"PDF olarak Çıktı Al",
    exportToExcel:	"Excel Olarak Çıktı Al",
    revenueReport:	"Gelir Raporu",
    header:	"Başlık",
    connection:	"Bağlantı",
    primaryKey:	"Birincil anahtar",
    secondaryKey:	"İkincil Anahtar",
    completedKey:	"Tamamlanan Anahtar",
    countKey:	"Sayım Anahtarı",
    statusKey	:"Durum Anahtarı",
    progressConnection:	"İlerleme Bağlantısı",
    progressScript	:"İlerleme Scripti",
    progressPrimaryKey:	"İlerleme Birincil Anahtarı",
    progressCountKey:	"İlerleme Sayısı Anahtarı",
    progressPercentageKey:	"İlerleme Yüzdesi Anahtarı",
    progressColorKey:	"İlerleme Renk Anahtarı",
    keyColumn:"	Değer Sütunu",
    currency:	"Para Birimi",
    whitePage:	"Beyaz Sayfa",
    noReportSelected:	"Rapor Seçilmedi",
    noTypeSelected:	"Rapor Tipi Seçilmedi",
    whitePageReports:	"Beyaz Sayfa Raporu",
    addBranch:	"Kırılım/Alt Kırılım Ekle",
    branchName:"	Kırılım Adı",
    rowNumber:	"Sıra Numarası",
    reportTypeSelect:	"Rapor Tipi Seçiniz",
    reportDescription:	"Rapor açıklaması...",
    useWeb:	 "Web'de Kullanılsın",
    useMobile:	"Mobil'de Kullanılsın",
    mainScript:	"Ana Script",
    prerunScript:	"Ön script çalıştır?",
    preScript:	"Ön Script",
    testIt:	"Test Et",
    selectAll:	"Tümünü Seç",
    addReport:	"Rapor Ekle",
    selectReportType:	"Rapor Tipi Seçiniz",
    reportType: "Rapor Tipi",
    reportDesingPermission:	"Kullanıcının rapor tasarım izni bulunmamaktadır.",
    reportImportedOpenLeftMenu:	"Rapor İçeri Aktarıldı. Sol Menüden Açabilirsiniz.",
    forReportExport:	"Rapor Export için önce rapor seçmelisiniz.",
    anErrorOccurred:	"Hata oluştu",
    reportDeleted:	"Rapor Silindi",
    branchDeleted:	"Kırılım Silindi",
    addBranchName:	"Lütfen kırılım adını giriniz",
    addedBranch	:"Kırılım Eklendi",
    selectConnectionAddress:"	Lütfen bağlantı adresi seçiniz",
    enterSqlScript	:"Lütfen sql script giriniz",
    enterReportName:	"Lütfen rapor adını giriniz",
    reportAdded	:"Rapor Eklendi",
    numberOfDataToBeProcessed	:"Bağlantı başarılı. İşlenecek veri sayısı:",
    dataNotFoundCheckYourConnection	:"Veri bulunamadı! Lütfen bağlantınızı ve sql komutunuzu kontrol ediniz",
    connectionNotFoundCheckYourConnection:	"Bağlantı sağlanamadı! Lütfen bağlantınızı ve sql komutunuzu kontrol ediniz",
    fillConnectionAndSql	:"Lütfen bağlantınızı ve sql komutunuzu doldurunuz",
    allTypesActivate	:"Görüntüleme Anında Bütün Tipler Aktif Olsun",
    passwordRequired	 : "Şifre gereklidir",
    showLess	:"Daha Az Göster",
    showMore	:"Daha Fazla Göster",
    authority	:"Yetki",
    preScriptDescription:	"Ön sccript açıklaması",
    enterReportFilters	:"Rapor Filtrelerini Giriniz",
    gridDesigner	:"Grid Tasarımcı",
    addMainBranch	:"Ana Kırılım Ekle",
    resetSettings:	"Ayarları Sıfırla",
    columsToGroup:	"Verileri gruplandırmak için başlık sütunlarının içerik menüsünü kullanın",
    customize	:"Özelleştirme",
    colorType:	"Renk Tipi",
    formatManagement:"Format Yönetimi",
    bottomTotalColumn:	"Dip Toplam Kolonu",
    formatType:	"Format Tipi",
    datagridSettingsReset:	"Data grid ayarları sıfırlanacak",
    unexpectedError	:"Üzgünüz, beklenmedik bir hata ile karşılaştık.",
    changesSaved	:"Değişiklikler kaydedildi",
    addGantTask	:"Gant Görevi Ekle",
    updGantTask	:"Gant Görevi Güncelle",
    updSchedulerTask	:"Takvim Görevi Güncelle",
    updTodoTask:	"To-Do Görevi Güncelle",
    completeTask	:"Görevi Tamamla",
    cantBeEmptyKanban	:"Görev başlığı, görev detayı ve görev atanan kişi boş olamaz",
    successAdd	:"Görev başarıyla eklendi",
    successUpd	:"Görev başarıyla güncellendi",
    statusChangeTodo	:"Görevin Tamamlanma Durumu Başarıyla Değiştirildi.",
    reminderSended	:"Görev için başarıyla hatırlatma maili gönderildi",
    askForCompleteTask	:"Görevi Tamamlamak İstediğinize Emin Misiniz?",
    infoForCompletedTask	:"Görevinize ait alt görevler de tamamlanacaktır.",
    noPermisison	:"Yetkiniz Bulunmamaktadır.",
    successDependencyAdded	:"Görev Bağımlılığı Başarıyla Eklendi",
    filterimportant	:"Önemine Göre Filtrele",
    enterEmail:	"E-posta adresi giriniz",
    reply	:"Cevapla",
    newCol	:"Yeni Sütun",
    thickness	:"Kalınlık",
    distanceToUpperComponent:	"Üstteki komponentle arasındaki mesafe",
    companyName	:"Şirket İsmi",
    showEmailModule	:"E-Posta modülünü göster",
    distanceToBottomComponent:	"Alttaki komponentle  arasındaki mesafe",
    canNonAdminUsersPost:	"Admin olmayan kullanıcılar post atabilsin mi",
    columnColor	:"Sütun Rengi",
    byWho	:"Tarafından",
    updated:	"Güncellendi",
    commentAdded	:"Yorum Eklendi",
    completed	:"Tamamlandı",
    addRemoveColumns:	"Sütun Ekle-Çıkar",
    searchMessage:	"Mesajlarda ara",
    riverReports	:"River BI Raporlar",
    forms	:"Formlar",
    searchinforms:	"Formlarda Ara",
    searchVoiceMessage:	"Aradığınız mesaj bu ses dosyasının içinde geçmektedir",
    searchVoiceMessages	:"Sesli mesajlarda arama",
    showSocialNotifications	:"Sosyal Bildirimlerini Göster",
    showProjectManagementNotifications	:"Proje Yönetimi Bildirimlerini Göster",
    showProcessNotifications	:"Süreç Bildirimlerini Göster",
    pageDesingPermission:	"Kullanıcının sayfa tasarım izni bulunmamaktadır.",
    filemanagementDesingPermission:	"Kullanıcının dosya yönetim izni bulunmamaktadır.",
    taskDelete	:"Görev Silinecek.",
    tasksWillDeleted:	"Görevi Silmek İstediğinize Emin Misiniz?.",
    componentSettings	:"Bileşen Ayarları",
    aboutComponent	:"Bileşen Hakkında",
    sureSaveChanges	:"Yaptığınız değişikleri kaydetmek istediğinizden emin misiniz?",
    showDeletedNotifications:	"Sildiğim Bildirimleri Göster",
    shareSometthing	:"Bir Şeyler Paylaş",
    resetPageLayout	:"Sayfa Tasarımını Sıfırla",
    downloadingSS:	"Ekran görüntüsü indiriliyor.Lütfen Bekleyiniz.",
    showHorizontalLines:	"Yatay Çizgiler Görünsün",
    turnOnVerticalHide	:"Dikey Gizleme Açılsın",
    whichHorizontalLinelinesAreNotVisible: "Hangi yatay çizgi -çizgiler görünmesin",
    priority: "Öncelik",
        boardSidebarMessageField: "Görev Mesaj Alanı",
        boardSidebarExtraField: "Görev Ekstra Bileşen Alanı",
        boardSidebarTaskHistory: "Hareket Geçmişi",
        loadMoreNotifications: "Daha Fazla Bildirim Göster",
        deletedNotifications: "Silinen Bildirimler",
        noFormNotification: "Henüz Süreç Bildirimi Bulunmuyor",
        noDeletedFormNotifications: "Silinen süreç bildirimi bulunmuyor",
        noDeletedBoardNotification: " Silinen Pano Bildirimi Bulunmuyor",
        noBoardNotification: " Henüz Pano Bildirimi Bulunmuyor",
        noDeletedSocialNotification: "Silinen Sosyal Medya Bildirimi Bulunmuyor",
        noSocialNotification: "Henüz Sosyal Medya Bildirimi Bulunmuyor",
        deleteAll: "Tümünü Sil",
        markAllRead: "Tümünü Okundu İşaretle",
        board: "Pano",
        form: "Form",
        formNotifications: "Form Bildirimleri",
        boardNotifications: "Pano Bildirimleri",
        socialNotifications: "Sosyal Bildirimleri",
        email: "E-posta",
        oldFormName: "Eski Form Adı",
        newFormName: "Yeni Form Adı",
        oldFormVariable: "Eski Form Değişkeni",
        newFormVariable: "Yeni Form Değişkeni",
        oldCategory: "Eski Kategori",
        newCategory: "Yeni Kategori",
        connectionInFile: "Dosyadaki Bağlantı",
        yourConnection: "Sizin Bağlantınız",
        element: "Element",
        script: "Script",
        formClosingEvent: "Form Kapanma Etkinliği",
        message: "Mesaj",
        formExportTextError: "Forma eklenen komponent yok ya da form kaydedilmemiş. İşlemin hatalı çalışmaması için lütfen bu sorunu düzeltiniz.",
        checkRequiedFields: "Zorunlu Alan Kontrolü Yapılsın",
        dontCheckRequiredFields: "Zorunlu Alan Kontrolü Yapılmasın",
        createPdfWarning: "Bu işaretlendiğinde, form kaydedilmez ve kapatılmaz",
        cratePdf: "PDF Oluştur",
        identityNumber: "TC Kimlik No",
        minPaymentHour: "Minimum Saat",
        maxPaymentHour: "Maksimum Saat",
        darkBlue: "Koyu Mavi",
        orange: "Turuncu",
        component: "Bileşen",
        properties: "Özellikler",

        dmsPath: "DMS Yolu",
        dashboard: "Dashboard",
        slider: "Slider",
        list: "Liste",
        otherImages: "Diğer Resimler",
        unitScript: "Birim Script",
        updatableMaxQuantity: "Güncellenebilir Maks. Miktar",
        newPage: "Yeni Sayfa",
        code: "Kod",
        resetHiddenComponent: "Gizlenen hedef komponentlerin değeri sıfırlansın",
        bold: "Kalın",
        italic: "İtalik",
        stringFormat: "String Format",
        videoUploaded: "Video Yüklendi",
        fillInAllFields: "Tüm Alanları Doldurun",
        normalPaging: "Normal Sayfalama",
        pageSize: "Sayfa Boyutu",
        special: "Özel",
        link: "Bağlantı",
        fontWeight: "Yazı Kalınlığı",
        pdfFileNotFound: "PDF Dosyası Bulunamadı",
        likedOn :"tarihinde beğendi",
        addedTask: "adlı görev eklendi",
        updatedTask: "adlı görev güncellendi",
        messageAddedToTask: "adlı göreve mesaj eklendi",
        deleteTask: "adlı görev silindi",
        completedTask: "adli görev tamamlandı",
        subCategory: "Alt Kategori",
        toTheBoard: "Panosuna",
        changeTasksPage: "Görevin Panosunu Değiştir",
        hideTaskDetail: "Görev Detayını Gizle",
        selectData: "Veri Seç",
        noContentYet: "Henüz İçerik Bulunmamaktadır.",
        addRowBetween: "Araya Satır Ekle",
        userPermissions: "Kullanıcı Yetkileri",
        askForPassword: "Şifre Sorulsun Mu?",
        passwordIsWrong: "Şifre hatalı, lütfen tekrar deneyin.",
        pleaseEnterPassword: "Lütfen devam etmek için kullanıcı şifrenizi giriniz.",
        onePaymentEachSave: "Her Kayıt İçin Tek Ödeme",
        riverModules: "RIVER Modülleri",
        processHistory: "Süreç Geçmişi",
        commentHasBeenMadeAtThisStage: "Yorum bu aşamada eklendi",
        clearSelection: "Seçimi Temizle",
        allowDownloadVersionHistoryDocuments: "Sürüm Geçmişi Belgelerini İndirmeye İzin Ver",

        delegated: "Devredildi",
        openDocxToPdf: "PDF Olarak Dosyayı Aç",
        activationStartDate: "Aktivasyon Başlangıç Tarihi",
        activationEndDate: "Aktivasyon Bitiş Tarihi",
        changeActivationDate: "Aktivasyon Tarihini Değiştir",
        showArchive: "Arşivi Göster",
        hideArchive: "Arşivi Gizle",
        jobListMessageArea: "İş Listesi Mesaj Alanı",
        generateLink: "Link Üret",
        fileLink: "Dosya Linki",
        givePasswordToTheLink: "Linke Şifre Ver",
        giveTheLinkAnExpirationDate: "Link'e son geçerlilik tarihi verin",
        tasks: "Görevler",
        maxFileCountLimit: "Maksimum Dosya Adedi",
        fileCountLimit: "Dosya Sayısı Sınırı",
        numberOfFilesExceeded: "Dosya sayısı aşıldı",
        numberOfFilesLimit: "Dosya sayısı Sınırı",
        lineIntermediateAllowAdd: "Araya satır ekle",
        lineIntermediateAllowCopy: "Araya satır kopyala",
        triggerReport: "Rapora Parametre Gönder",
        setWhitePageReportExportName: "Beyaz Sayfa Raporunun Dışarı Aktarma İsmini Belirle",
        menuRowUpdated: "Bu otomatik artan verisiyle kayıtlı veri bulunmaktadır. Otomatik artan numarası güncellendi: ",
        isMenuRowControl: "Otomatik artan kontrolü",
        sqlScriptWarning: "Bu alanı kullanmak performans kayıplarına sebep olabilir.",
        deletedTask: "Silinmiş Görev",
        getDeletedTasks: "Silinmiş Görevleri Getir",
        getTasks: "Görevleri Getir",
        movePageDesignersNode: "Seçili Pano/Menü Klasöre Taşınacaktır",
        informationAboutMoveToDirectory: "Taşınan Pano/Menü'nün kullanıcı yetkileri, içine aktarılan klasörün yetkisine göre değişecektir. ",
        move: "Taşı",
        download: "İndir",
        create: "Oluştur",
        statistic: "İstatistik",
        priRate: "Öncelik Sırasına Göre Atama Oranı",
        taskcreatorlist: "Görev Oluşturanların Listesi",
        completionRate: "Tamamlanma Oranı",
        columnsTaskCount: "Sütunlardaki Görev Sayısı",
        showKanbanFilter: "Kanban Filtrelerini Göster",
        processToStart: "Başlatılacak Süreç",
        fieldMatching: "Alan Eşleştirme",
        showFileHistory: "Dosya Geçmişini Göster",
        dontLetDeleteWhatTheyAdded: "Kendi eklediğini silemesin",
        
    }

}
