import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { SchemeModel } from '@shared/models/scheme-model';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {
         
    }

    getCategoryList() {
        return this.http.get(this.appInfo.appUrl + "api/task/getcategorylist", this.appInfo.httpOptions);
    }

    getSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschemalist", { params: params });
    }

    getJustSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getjustschemalist", { params: params });
    }

    getSidebarMenu(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getsidebarmenulist", { params: params });
    }

    getMenuList(): Observable<any> {
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getmenulist", this.appInfo.httpOptions);
    }
    getSchedulerMenuAndFormDatas(): Observable<any> {
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschedulermenuandformdatas", this.appInfo.httpOptions);
    }

    SetFavorite(schemeId): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("schemeId", schemeId);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/updatefavorite", { params: params });
    }
    addClickedUsersForChat(userId:number,instanceId:number) {
       
        return this.http.post<any>(`${this.appInfo.appUrl}api/task/addclickedusersforchat?userId=${userId}&instanceId=${instanceId}`, this.appInfo.httpOptions);
    }


    GetProcessStarterId(processFormId): Observable<any> {

        let params = new HttpParams().set("processFormId", processFormId);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getprocessstarterid", { params: params });
    }
    setTaskChatData(model: any) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/task/settaskchatdata", JSON.stringify(model), this.appInfo.httpOptions);
    } 
    updateTaskChatData(id: number, content: string) {
        return this.http.post<any>(`${this.appInfo.appUrl}api/task/updatetaskchatdata?id=${id}&content=${content}`, this.appInfo.httpOptions);
    }
    deleteTaskChatData(id: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/deletetaskchatdata?id=" + id, this.appInfo.httpOptions);

    }
    getTaskChatData(instanceId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/task/gettaskchatdata?instanceId=" + instanceId, this.appInfo.httpOptions);

    }
 

    GetFavoriteList(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getfavoritelist", { params: params });
    }

    getTaskList(userId, filter) { //paging �ncesi,art�k kullan�lm�yor
         
        let params = new HttpParams().set("uId", userId).set("filter", filter);
        
        return this.http.get(this.appInfo.appUrl + "api/task/gettasks", { params: params });
    }

    getHistoryPage(id) { //paging �ncesi,art�k kullan�lm�yor
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("type", id);
        
        return this.http.get(this.appInfo.appUrl + "api/task/gethistorypage", { params: params });
    }
    getWaitingJobsData(userId: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/getwaitingjobsdata?userId=", userId, this.appInfo.httpOptions);

    }
    setUserPhoto(tasks) {
      

        tasks.forEach(data => {
          
            var user = this.appInfo.RiverEntity.users.find(f => data.startedUserId == f.id);

            if (user != null ) {
               
                user.PhotoUrl = this.appInfo.appUrl + "api/filemanagement/getUserPhoto?id=" + data.startedUserId + '&type=1'
            }

            else {
                user = {};
                user.fullname = "Aktif olmayan kullan�c�"
                user.email = ""
                user.hasPhoto = false;
            }

           
               
            
            var dataIndex = tasks.findIndex(f => data.id == f.id)

            tasks[dataIndex].startedUserEntity = user;

        });
        
        //tasks.startedUserHeaderFilter = startedUserHeaderFilter;

    }
    getNewTaskListWithPaging(pagingObj: any) {   
        return new Promise((resolve, reject) => {
            return this.http.post(this.appInfo.appUrl + "api/task/gettaskswithpaging", JSON.stringify(pagingObj), this.appInfo.httpOptions)
                .subscribe(
                    (response: any) => {
                        response.data.map(f => {
                            f.formname = f.form.name
                            f.formstatus = f.form.status
                        });
                      
                         this.setUserPhoto(response.data);
                        resolve(response);
                    }, (error: any) => {
                        reject(error)
                    }
                )
        })
    }

    setHeaderFilters(obj) {
        debugger
        let formNoHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let formNameHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let startedUserHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let startDateHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let allDescHeaderFilter = {
           dataSource: [] as { text: string; value: [string, string, string] }[]
             };
        let allDesc2HeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let allAssignedDateHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
       
        
   
        const instanceNumbers = obj.data.map(f => f.instanceNumbers);
        instanceNumbers[0].split(',').forEach(number => {
            formNoHeaderFilter.dataSource.push({
                text: number,
                value: ["formNo", "=", number]
            });
        });

        const startDates = obj.data.map(f => f.allStartDates);
        startDates[0].split(',').forEach(date => {
            startDateHeaderFilter.dataSource.push({
                text: date,
                value: ["startDate", "=", date]
            });
        });

        const formNames = obj.data.map(f => f.allFormNames);
        formNames[0].split(',').forEach(formName => {
            formNameHeaderFilter.dataSource.push({
                text: formName,
                value: ["formname", "=", formName]
            });
        })
        
        const names = obj.data.map(n => n.allNames);
        names[0].split(',').forEach(headerData => {
            startedUserHeaderFilter.dataSource.push({
                text: headerData,
                value: ["startedUserEntity.fullName", "=", headerData]
            });
        });
        //const descriptions = obj.data.map(n => n.allDescriptions);
        //descriptions[0].split(',').forEach(headerdata => {
        //    allDescHeaderFilter.dataSource.push({
        //        text: headerdata,
        //        value: ["formstatusbyuser", "=", headerdata]
        //    });
        //});
        //const descriptions2 = obj.data.map(n => n.allDescriptions2);
        //descriptions2[0].split(',').forEach(headerdata => {
        //    allDesc2HeaderFilter.dataSource.push({
        //        text: headerdata,
        //        value: ["formsituationbyuser", "=", headerdata]
        //    });
        //});
        const AllAssignedDate = obj.data.map(n => n.allAssignedDate);
        AllAssignedDate[0].split(',').forEach(headerData => {
            allAssignedDateHeaderFilter.dataSource.push({
                text: headerData,
                value: ["assignedDate", "=", headerData]
            });
        });


        return { formNoHeaderFilter, formNameHeaderFilter, startedUserHeaderFilter,/*allDescHeaderFilter, allDesc2HeaderFilter,*/ startDateHeaderFilter, allAssignedDateHeaderFilter };
    }


    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
